// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karatekapp-js": () => import("./../../../src/pages/karatekapp.js" /* webpackChunkName: "component---src-pages-karatekapp-js" */),
  "component---src-pages-wordtarget-setup-js": () => import("./../../../src/pages/wordtarget/setup.js" /* webpackChunkName: "component---src-pages-wordtarget-setup-js" */),
  "component---src-templates-app-template-js": () => import("./../../../src/templates/appTemplate.js" /* webpackChunkName: "component---src-templates-app-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-content-list-template-js": () => import("./../../../src/templates/contentListTemplate.js" /* webpackChunkName: "component---src-templates-content-list-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

